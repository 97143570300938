import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  Select,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import ButtonTheme from 'modules/shared/ButtonTheme';
import { useWizard } from 'react-use-wizard';
import FormErrorMessage from 'modules/shared/FormErrorMessage';
import {
  countries,
  employmentStatus,
  sourceOfFunds,
  states,
} from 'utils/constants';
import { formatDateToISO, toastSuccess } from 'utils/helpers';
import {
  getAccountTypeFromLocalStorage,
  setFieldValueToLocalStorage,
} from 'services/localStorage.sevice';
import { saveProfile } from 'services/user.service';
import { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { ServiceOption } from 'modules/onboarding/business/broker';
import useFormLocalStorage from 'hooks/useFormLocalStorage';
import VerifyingBusinessTransition from '../Verifying-business-transition';
import { CrossIcon } from 'modules/shared/Icons';

const VerifyIdentity = () => {
  const [selected, setSelected] = useState([]);
  const [countriesError, setCountriesError] = useState<any>(null);
  const [ssn, setSSN] = useState('');
  const [currentScreen, setCurrentScreen] = useState('verifyIdentity');

  const formatSSN = (value: any) => {
    const digits = value.replace(/\D/g, '').slice(0, 9);
    const formatted = digits
      .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
      .replace(/(\d{3})(\d{2})(\d{0,4})/, '$1-$2-$3');
    return formatted;
  };
  const handleRemove = (optionToRemove: ServiceOption) => {
    // const updatedServices = formData.services.filter(value => value !== optionToRemove.value);
    // setFormData({ ...formData, services: updatedServices });
  };

  const customValueRenderer = (
    selected: ServiceOption[],
    handleRemove: (option: ServiceOption) => void
  ) => {
    return selected.length ? (
      selected.map(({ label, value }) => (
        <Text
          color={'Primary.Blue'}
          bgColor={'Neutral.100'}
          py={1}
          px={2}
          borderRadius={40}
          fontSize={'xs'}
          cursor={'pointer'}
          display={'inline-flex'}
          key={value}
          alignItems={'center'}
          gap={1}
          mr={1}
        >
          {label}
          <CrossIcon
            w={2}
            h={2}
            onClick={() => handleRemove({ label, value })}
          />
        </Text>
      ))
    ) : (
      <span>Select</span>
    );
  };

  const { nextStep, previousStep } = useWizard();
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm();

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const onSubmit = async (values: any) => {
    if (selected.length === 0) {
      setCountriesError('At least one country is required');
      return;
    }
    const {
      legal_first_name,
      legal_last_name,
      citizenship,
      source_of_funds,
      employment_status,
      ssn,
      day,
      month,
      year,
      city,
      state,
      zip_code,
      address_1,
      address_2,
    } = values;
    const formattedDob = formatDateToISO(day, month, year);

    const valuesArray = selected.map((item: any) => item.value);

    const healUsageJson = JSON.stringify(valuesArray);
    const userProfile = {
      account_type: getAccountTypeFromLocalStorage(),
      legal_first_name,
      legal_last_name,
      heal_usage: healUsageJson,
      citizenship,
      source_of_funds,
      employment_status,
      ssn,
      dob: formattedDob,
      city,
      state,
      zip_code,
      address_1,
      address_2,
    };
    const response = await saveProfile(userProfile);
    if (response?.status) {
      toastSuccess(response?.data?.message);
      setCurrentScreen('verifying');
    }
  };

  const { handleChange, getInitialValues } = useFormLocalStorage(
    'verifyIdentity',
    setValue
  );

  useEffect(() => {
    const initialValues = getInitialValues();
    Object.keys(initialValues).forEach((key) => {
      if (key === 'heale_usage') {
        setSelected(initialValues[key]);
      } else {
        setValue(key, initialValues[key]);
      }
    });
  }, []);

  const handleSelectChange = (selectedOptions: any) => {
    setSelected(selectedOptions);
    handleChange('heale_usage', selectedOptions);
  };

  const {
    onChange: ssnOnChange,
    ref: ssnRef,
    ...ssnRest
  } = register('ssn', {
    required: 'This field is required',
    onChange: (e) => handleChange('ssn', e.target.value),
    validate: (value) =>
      value.replace(/\D/g, '').length === 9 || 'SSN must be exactly 9 digits',
    onBlur: () => trigger('ssn'),
  });

  return (
    <Box>
      {currentScreen === 'verifyIdentity' ? (
        <>
          <Box textAlign={'center'}>
            <Heading as={'h4'} mb={4} fontSize={'3xl'} color={'Primary.Navy'}>
              Verify your identity
            </Heading>
            <Text mb={8} color={'Neutral.800'}>
              We have a few questions to verify your identity for compliance
              purposes. Your information is transmitted and stored securely.
            </Text>
          </Box>
          <Box
            sx={{
              '.chakra-form__label': {
                fontSize: 'sm',
                color: 'Primary.Navy',
              },
              '.chakra-input': {
                fontSize: 'sm',
              },
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                mb={6}
                gridTemplateColumns={{
                  lg: 'repeat(3,1fr)',
                  base: 'repeat(1,1fr)',
                }}
                gap={{ lg: 6, base: 0 }}
                rowGap={{ lg: 0, base: 6 }}
              >
                <FormControl>
                  <FormLabel htmlFor="legal_first_name">
                    Legal First Name
                  </FormLabel>
                  <Input
                    type="text"
                    isInvalid={errors?.legal_first_name?.message ? true : false}
                    errorBorderColor="Secondary.Red"
                    placeholder="Legal First Name"
                    {...register('legal_first_name', {
                      required: 'This field is required',
                      minLength: {
                        value: 4,
                        message: 'Minimum length should be 4',
                      },
                      onBlur: () => trigger('legal_first_name'),
                      onChange: (e) =>
                        handleChange('legal_first_name', e.target.value),
                    })}
                  />
                  <FormErrorMessage
                    message={errors?.legal_first_name?.message}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="legal_last_name">
                    Legal Last Name
                  </FormLabel>
                  <Input
                    type="text"
                    isInvalid={errors?.legal_last_name?.message ? true : false}
                    errorBorderColor="Secondary.Red"
                    placeholder="Legal Last Name"
                    {...register('legal_last_name', {
                      required: 'This field is required',
                      minLength: {
                        value: 4,
                        message: 'Minimum length should be 4',
                      },
                      onBlur: () => trigger('legal_last_name'),
                      onChange: (e) =>
                        handleChange('legal_last_name', e.target.value),
                    })}
                  />
                  <FormErrorMessage
                    message={errors?.legal_last_name?.message}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="heal_usage">
                    What countries do you operate in?
                  </FormLabel>
                  <MultiSelect
                    className="custom-multi-select2"
                    options={countries}
                    hasSelectAll={true}
                    value={selected}
                    onChange={handleSelectChange}
                    labelledBy={'Select'}
                    valueRenderer={(selected, options) =>
                      customValueRenderer(
                        selected as ServiceOption[],
                        handleRemove
                      )
                    }
                  />
                  <FormErrorMessage
                    message={countriesError ? countriesError : ''}
                  />
                </FormControl>
              </Grid>
              <Grid
                mb={6}
                gridTemplateColumns={{
                  lg: 'repeat(3,1fr)',
                  base: 'repeat(1,1fr)',
                }}
                gap={{ lg: 6, base: 0 }}
                rowGap={{ lg: 0, base: 6 }}
              >
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel htmlFor="dob">Date of birth</FormLabel>
                    <Grid gridTemplateColumns={'repeat(3,1fr)'} gap={6}>
                      <Box>
                        <Select
                          placeholder="Month"
                          isInvalid={errors?.month?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          {...register('month', {
                            required: 'This field is required',
                            onBlur: () => trigger('month'),
                            onChange: (e) =>
                              handleChange('month', e.target.value),
                          })}
                        >
                          {Array.from({ length: 12 }, (_, i) => i + 1).map(
                            (month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            )
                          )}
                        </Select>
                        <FormErrorMessage message={errors?.month?.message} />
                      </Box>
                      <Box>
                        <Select
                          placeholder="Day"
                          isInvalid={errors?.day?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          {...register('day', {
                            required: 'This field is required',
                            onBlur: () => trigger('day'),
                            onChange: (e) =>
                              handleChange('day', e.target.value),
                          })}
                        >
                          {Array.from({ length: 31 }, (_, i) => i + 1).map(
                            (day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            )
                          )}
                        </Select>
                        <FormErrorMessage message={errors?.day?.message} />
                      </Box>
                      <Box>
                        <Select
                          placeholder="Year"
                          isInvalid={errors?.year?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          {...register('year', {
                            required: 'This field is required',
                            onBlur: () => trigger('year'),
                            onChange: (e) =>
                              handleChange('year', e.target.value),
                          })}
                        >
                          {Array.from({ length: 100 }, (_, i) => 2020 - i).map(
                            (year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            )
                          )}
                        </Select>
                        <FormErrorMessage message={errors?.year?.message} />
                      </Box>
                    </Grid>
                  </FormControl>
                </GridItem>
                <FormControl>
                  <FormLabel>What is your source of funds?</FormLabel>
                  <Select
                    isInvalid={errors?.source_of_funds?.message ? true : false}
                    errorBorderColor="Secondary.Red"
                    placeholder="Select"
                    {...register('source_of_funds', {
                      required: 'This field is required',
                      onBlur: () => trigger('source_of_funds'),
                      onChange: (e) =>
                        handleChange('source_of_funds', e.target.value),
                    })}
                  >
                    {sourceOfFunds.map((source, index) => (
                      <option key={index} value={source}>
                        {source}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage
                    message={errors?.source_of_funds?.message}
                  />
                </FormControl>
              </Grid>
              <Grid
                mb={6}
                gridTemplateColumns={{
                  lg: 'repeat(3,1fr)',
                  base: 'repeat(1,1fr)',
                }}
                gap={{ lg: 6, base: 0 }}
                rowGap={{ lg: 0, base: 6 }}
              >
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel htmlFor="">Street address</FormLabel>
                    <Grid gridTemplateColumns={'repeat(3,1fr)'} gap={5}>
                      <GridItem colSpan={3}>
                        <Input
                          type="text"
                          isInvalid={errors?.address_1?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          placeholder="Address line 1"
                          {...register('address_1', {
                            required: 'This field is required',
                            onBlur: () => trigger('address_1'),
                            onChange: (e) =>
                              handleChange('address_1', e.target.value),
                          })}
                        />
                        <FormErrorMessage
                          message={errors?.address_1?.message}
                        />
                      </GridItem>
                      <GridItem colSpan={3}>
                        <Input
                          type="text"
                          placeholder="Address line 2"
                          {...register('address_2', {
                            onChange: (e) =>
                              handleChange('address_2', e.target.value),
                          })}
                        />
                      </GridItem>
                      <GridItem colSpan={2}>
                        <Input
                          type="text"
                          isInvalid={errors?.city?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          placeholder="City"
                          {...register('city', {
                            required: 'This field is required',
                            onBlur: () => trigger('city'),
                            onChange: (e) =>
                              handleChange('city', e.target.value),
                          })}
                        />
                        <FormErrorMessage message={errors?.city?.message} />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Select
                          isInvalid={errors?.state?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          placeholder="State"
                          {...register('state', {
                            required: 'This field is required',
                            onBlur: () => trigger('state'),
                            onChange: (e) =>
                              handleChange('state', e.target.value),
                          })}
                        >
                          {states.map((state, index) => (
                            <option key={index} value={state?.label}>
                              {state?.label}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage message={errors?.state?.message} />
                      </GridItem>
                      <GridItem colSpan={3}>
                        <Input
                          type="text"
                          isInvalid={errors?.zip_code?.message ? true : false}
                          errorBorderColor="Secondary.Red"
                          placeholder="Zip code"
                          {...register('zip_code', {
                            required: 'This field is required',
                            onBlur: () => trigger('zip_code'),
                            onChange: (e) =>
                              handleChange('zip_code', e.target.value),
                          })}
                        />
                        <FormErrorMessage message={errors?.zip_code?.message} />
                      </GridItem>
                    </Grid>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl mb={6}>
                    <FormLabel>Employment status</FormLabel>
                    <Select
                      isInvalid={
                        errors?.employment_status?.message ? true : false
                      }
                      errorBorderColor="Secondary.Red"
                      placeholder="Select"
                      {...register('employment_status', {
                        required: 'This field is required',
                        onBlur: () => trigger('employment_status'),
                        onChange: (e) =>
                          handleChange('employment_status', e.target.value),
                      })}
                    >
                      {employmentStatus.map((status, index) => (
                        <option key={index} value={status}>
                          {status}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage
                      message={errors?.employment_status?.message}
                    />
                  </FormControl>
                  <FormControl mb={6}>
                    <FormLabel>Social Security number</FormLabel>
                    <Input
                      type="text"
                      isInvalid={errors?.ssn?.message ? true : false}
                      errorBorderColor="Secondary.Red"
                      value={ssn}
                      onChange={(event) => {
                        const formattedSSN = formatSSN(event.target.value);
                        setSSN(formattedSSN);
                        ssnOnChange({
                          target: {
                            name: 'ssn',
                            value: formattedSSN,
                          },
                        });
                      }}
                      
                      ref={ssnRef}
                      {...ssnRest}
                    />
                    <FormErrorMessage message={errors?.ssn?.message} />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Citizenship</FormLabel>
                    <Select
                      isInvalid={errors?.citizenship?.message ? true : false}
                      errorBorderColor="Secondary.Red"
                      placeholder="Select"
                      {...register('citizenship', {
                        required: 'This field is required',
                        onBlur: () => trigger('citizenship'),
                        onChange: (e) =>
                          handleChange('citizenship', e.target.value),
                      })}
                    >
                      {countries.map((country) => (
                        <option key={country.label} value={country.label}>
                          {country.label}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage message={errors?.citizenship?.message} />
                  </FormControl>
                </GridItem>
              </Grid>
              <Flex flexDir={'column'} alignItems={'center'} mt={8} gap={6}>
                <ButtonTheme
                  isLoading={isSubmitting}
                  type="submit"
                  btnText="Continue"
                  isDisabled={!isDirty || !isValid}
                  chakraProps={{
                    w: '35%',
                  }}
                  primary
                />
                <Link color="Primary.Blue" onClick={() => previousStep()}>
                  Back
                </Link>
              </Flex>
            </form>
          </Box>
        </>
      ) : (
        <VerifyingBusinessTransition
          type={'identity'}
          handleNext={nextStep}
        />
      )}
    </Box>
  );
};

export default VerifyIdentity;
