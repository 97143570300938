import { Box, Checkbox, Flex, Heading, Link, Text } from '@chakra-ui/react'
import ButtonTheme from 'modules/shared/ButtonTheme';
import { HealeLogoWhite, WifiIcon } from 'modules/shared/Icons';
import React, { useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { getAccountTypeFromLocalStorage, removeTokenFromLocalStorage } from 'services/localStorage.sevice';
import { usePersistedStep } from './WizardHeader';
import { sendOnboardedEmail } from 'services/user.service';

const RecieveCard = () => {
    const [step, setStep, clearStep] = usePersistedStep(0);
    const { nextStep, previousStep } = useWizard();
    const [showShippingAddress, setShowShippingAddress] = useState(false);

    const handleCheckboxChange = () => {
        setShowShippingAddress(!showShippingAddress);
    };


    return (
        <Box w={{ lg: '50%', md: "80%", base: '100%' }} m={'0 auto'}>
            <Box textAlign={"center"}>
                <Heading as={"h4"} mb={4} fontSize={"3xl"} color={"Primary.Navy"}>Personal HEALE Virtual Card</Heading>
                <Text mb={8} color={"Neutral.800"}>User receives personal bank account and decides if they want to select to receive a physical copy of the card</Text>
            </Box>
            <Flex mb={8} flexDir={"column"} justifyContent={"space-between"} h={{ xl: 80, sm: 60, base: 48 }}
                bgColor={"Primary.Blue"}
                bgImage={"/noise.png"}
                bgSize={"cover"}
                boxShadow={"0px 0px 20px 0px rgba(3, 16, 61, 0.25)"}
                bgGradient={"radial-gradient(163.27% 64.37% at 121.3% 37.79%, #03103D 0%, rgba(3, 16, 61, 0) 100%),radial-gradient(82.72% 80.43% at -8.95% -58.14%, #03103D 0%, rgba(3, 16, 61, 0) 100%),radial-gradient(122.53% 48.31% at -40.12% 135.08%, #03103D 0%, rgba(3, 16, 61, 0) 100%) "}
                borderRadius={"24px"} p={{ xl: 8, sm: 4, base: 6 }} pt={2}>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Box>
                        <HealeLogoWhite ms={-5} w={{ xl: 52, sm: 40, base: 32 }} h={{ xl: 20, sm: 16, base: 12 }} />
                    </Box>
                    <WifiIcon w={{ xl: 10, sm: 6, base: 5 }} h={{ xl: 10, sm: 6, base: 5 }} />
                </Flex>
                <Flex mt={-5} justifyContent={"space-between"} color={"white"} alignItems={"center"}>
                    <Heading fontSize={{ xl: "3xl", sm: "xl", base: "lg" }}>•••• •••• ••••  3090</Heading>
                    <Text fontSize={{ xl: "3xl", sm: "xl", base: "lg" }}>CCV 341</Text>
                </Flex>
                <Flex justifyContent={"space-between"} alignItems={"center"} fontSize={{ xl: "xl", sm: "lg", base: "md" }} color={"white"}>
                    <Flex gap={4}>
                        <Text >Jeff Bridges</Text>
                        <Text >09/25</Text>
                    </Flex>
                    <Text>Personal</Text>
                </Flex>
            </Flex>
            <Checkbox color={"Primary.Navy"} size={"sm"} onChange={handleCheckboxChange}>Option to order a physical copy of your personal & business cards</Checkbox>
            {
                showShippingAddress && (
                    <Box p={6} my={8} borderRadius={8} border={"1px solid"} borderColor={"Neutral.200"}>
                        <Flex justifyContent={"space-between"}>
                            <Heading fontSize={"md"} color={"Primary.Navy"}>Shipping Address</Heading>
                            {/* <ButtonTheme btnText='Edit' chakraProps={{
                                fontSize: "xs",
                                h: 8
                            }} /> */}
                        </Flex>
                        <Text w={"170px"} color={"Primary.Navy"} fontSize={"sm"}>525 Brannon Street Unit 24
                            Los Angeles, CA 90026</Text>
                    </Box>
                )
            }
            <Flex flexDir={'column'} alignItems={'center'} gap={6} mt={!showShippingAddress ? 8 : 0}>

                <ButtonTheme btnText='Continue' primary chakraProps={{
                    w: { md: '35%', base: "60%" },
                    onClick: async () => {
                        let type = getAccountTypeFromLocalStorage();
                        if (type === 'Personal') {
                            clearStep();
                            await sendOnboardedEmail();
                            window.location.href = '/extension'
                        } else {
                            nextStep()
                        }
                    }
                    // onClick: () => nextStep(),
                }} />
                <Link color="Primary.Blue" onClick={() => previousStep()}>
                    Back
                </Link>
            </Flex>
        </ Box >
    )
}

export default RecieveCard